import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AlgorithmState } from './algorithm.reducer';

export const algorithmFeatureKey = 'algorithm';

export const selectAlgorithmState =
  createFeatureSelector<AlgorithmState>(algorithmFeatureKey);

export const selectAlgorithm = (manifestationId: string) =>
  createSelector(selectAlgorithmState, (state) => {
    return state.entities[manifestationId] || [];
  });

export const selectProblemStatements = (
  manifestationId: string,
  nodeId: string
) =>
  createSelector(selectAlgorithm(manifestationId), (algorithm) => {
    let problemStatements: string[] = [];
    let currentNode = algorithm.find((node) => node.id === nodeId);
    const manifestation = algorithm.find((node) => node.id === manifestationId);

    while (currentNode !== undefined && currentNode.problemStatement) {
      problemStatements.push(currentNode.problemStatement);
      currentNode = algorithm.find((node) => node.id === currentNode?.parentId);
    }

    if (manifestation) {
      problemStatements.push(manifestation.name);
    }

    problemStatements.reverse();
    problemStatements = filterDuplicatedProblemStatements(problemStatements);

    return problemStatements.slice(1);
  });

function filterDuplicatedProblemStatements(
  problemStatements: string[]
): string[] {
  const uniqueProblemStatements: string[] = [];

  problemStatements.forEach((statement, k) => {
    if (
      uniqueProblemStatements[uniqueProblemStatements.length - 1] !== statement
    ) {
      uniqueProblemStatements.push(statement);
    }
  });

  return uniqueProblemStatements;
}
