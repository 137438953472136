import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { inject } from '@angular/core';
import { take, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export enum ConfigStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  INTERNAL = 'INTERNAL',
}

export interface Config {
  id: string;
  key: string;
  value: string;
  app: string;
  status: ConfigStatus;
}

export interface Flags {
  id: string;
  key: string;
  value: boolean;
  app: string;
  status: ConfigStatus;
}

export interface ConfigState {
  configs: Config[];
  flags: Flags[];
}

export interface FlagsResponse {
  serviceFlags: {
    flags: Flags[];
  };
}

export interface ConfigResponse {
  serviceConfigs: {
    flags: Config[];
  };
}

export interface QueryParams {
  app: string;
  items: {
    key: {
      getItem: boolean;
    };
    value: {
      getItem: boolean;
    };
    status: {
      getItem: boolean;
    };
  };
}

export const ConfigStore = signalStore(
  { providedIn: 'root' },
  withState<ConfigState>({
    configs: [],
    flags: [],
  }),
  withMethods((store, http = inject(HttpClient)) => ({
    getFlags(queryParams: QueryParams): void {
      http
        .post<{ data: FlagsResponse }>('/config', buildFlagQuery(queryParams), {
          withCredentials: true,
          headers: { 'Content-Type': 'application/json' },
        })
        .pipe(
          take(1),
          tap(({ data }) => {
            patchState(store, (state) => ({
              ...state,
              flags: data.serviceFlags.flags,
            }));
          })
        )
        .subscribe();
    },
    getConfigs(queryParams: QueryParams): void {
      http
        .post<{ data: ConfigResponse }>(
          '/config',
          buildConfigQuery(queryParams),
          {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json' },
          }
        )
        .pipe(
          take(1),
          tap(({ data }) => {
            patchState(store, (state) => ({
              ...state,
              configs: data.serviceConfigs.flags,
            }));
          })
        )
        .subscribe();
    },
  })),
  withHooks({
    onInit(store) {
      store.getFlags({
        app: 'frontend-app',
        items: {
          key: { getItem: true },
          value: { getItem: true },
          status: { getItem: true },
        },
      });
      store.getConfigs({
        app: 'backend-service',
        items: {
          key: { getItem: true },
          value: { getItem: true },
          status: { getItem: true },
        },
      });
    },
  })
);

export function buildFlagQuery(queryParams: QueryParams): string {
  const items = (
    Object.keys(queryParams.items) as Array<'key' | 'value' | 'status'>
  )
    .filter((key) => queryParams.items[key].getItem === true)
    .map((key) => key)
    .join('\n');
  const query = `
    query Flag {
      serviceFlags(app: "${queryParams.app}") {
        flags {
          ${items}
        }
      }
    }
  `;

  const formattedQuery = JSON.stringify({
    query: query,
  });
  return formattedQuery;
}

export function buildConfigQuery(queryParams: QueryParams): string {
  const items = (
    Object.keys(queryParams.items) as Array<'key' | 'value' | 'status'>
  )
    .filter((key) => queryParams.items[key].getItem === true)
    .map((key) => key)
    .join('\n');
  const query = `
    query Config {
      serviceConfigs(app: "${queryParams.app}") {
        config {
          ${items}
        }
      }
    }
  `;

  const formattedQuery = JSON.stringify({
    query: query,
  });
  return formattedQuery;
}
