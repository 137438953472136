export const taxonomyResponse = [
  {
    id: 'bb2b5af0-7527-4ad1-a590-13821ca19b1f',
    title: 'Abdominal Pain',
    classification: ['problem', 'symptom'],
    path: 'abdominal-pain',
    isDisabled: true,
  },
  {
    id: '241866e6-b22a-4f1c-bba6-e3b6aba915ea',
    title: 'Osteoporosis',
    classification: ['problem', 'labAbnormality'],
    path: 'osteoporosis',
    isDisabled: true,
  },
  {
    id: '8b4b9997-e41a-43b8-823e-11099ade072d',
    title: 'Chest Discomfort',
    classification: ['problem', 'symptom'],
    path: 'chest-discomfort',
    isDisabled: true,
  },
  {
    id: 'f2abf4fc-c48f-4845-a6b9-0716e735ff1a',
    title: 'Eczema',
    classification: ['problem', 'sign'],
    path: 'eczema',
    isDisabled: true,
  },
  {
    id: '6be88d87-2d5a-4f7b-a409-fd0ba3078bd3',
    title: 'Edema',
    classification: ['problem', 'sign'],
    path: 'edema',
    isDisabled: true,
  },
  {
    id: '8e50bc65-5725-4a84-ac12-47cb08d4f60d',
    title: 'Hypertension',
    classification: ['problem', 'labAbnormality'],
    path: 'hypertension',
    isDisabled: true,
  },
  {
    id: '2fc51630-79df-4be4-9fa0-d5881a6af3d0',
    title: 'Jaundice',
    classification: ['problem', 'sign'],
    path: 'jaundice',
    isDisabled: true,
  },
  {
    id: 'a97a970f-ac82-47a8-b02d-54ec32517dab',
    title: 'Lower Abdominal Pain',
    classification: ['problem', 'symptom'],
    path: 'lower-abdominal-pain',
    isDisabled: true,
  },
  {
    id: 'c38f508f-bb63-4a71-86ee-a1575e35ce0d',
    title: 'Palpitations',
    classification: ['problem', 'symptom'],
    path: 'palpitations',
    isDisabled: true,
  },
  {
    id: '4d352715-e37f-4abf-bdd7-be2a320eff39',
    title: 'Transient Loss of Consciousness',
    classification: ['problem', 'sign'],
    path: 'transient-loss-of-consciousness',
    isDisabled: false,
  },
  {
    id: 'c2b423e1-c939-4bd7-a142-62563d5c179a',
    title: 'Unintentional Weight Loss',
    classification: ['problem', 'sign'],
    path: 'unintentional-weight-loss',
    isDisabled: true,
  },
  {
    id: 'fb575224-3fb1-4186-8a3b-e484fe8544e5',
    title: 'Alcohol Poisoning',
    classification: ['disease'],
    path: 'alcohol-poisoning',
    isDisabled: true,
  },
  {
    id: 'a7012a14-c258-4cbf-b6f5-7fb162e20c64',
    title: 'Aortic Dissection',
    classification: ['disease'],
    path: 'aortic-dissection',
    isDisabled: true,
  },
  {
    id: 'd9d8155f-2301-4e5f-ab08-a55223ae0850',
    title: 'Aortic Stenosis',
    classification: ['disease'],
    path: 'aortic-stenosis',
    isDisabled: true,
  },
  {
    id: 'f8109f27-2425-4603-ae36-d3a953f3b3d2',
    title: 'Arrhythmias',
    classification: ['disease'],
    path: 'arrhythmias',
    isDisabled: true,
  },
  {
    id: '60ee836e-7f35-46e5-91c8-44d8e8cd5818',
    title: 'Atrial Myxoma',
    classification: ['disease'],
    path: 'atrial-myxoma',
    isDisabled: true,
  },
  {
    id: 'c3bf842d-f7f5-4e39-9a44-9d0a98ff6f0c',
    title: 'Atrioventricular Block',
    classification: ['disease'],
    path: 'atrioventricular-block',
    isDisabled: true,
  },
  {
    id: 'c45656f7-9f6a-47fe-809d-375fb37c2d07',
    title: 'Cardiac Syncope',
    classification: ['disease'],
    path: 'cardiac-syncope',
    isDisabled: true,
  },
  {
    id: 'f340110f-75df-4c1b-8a17-f8e58a39b974',
    title: 'Cerebrovascular Disease',
    classification: ['disease'],
    path: 'cerebrovascular-disease',
    isDisabled: true,
  },
  {
    id: '379b3168-c249-422a-bfde-c3c5db750fb3',
    title: 'Hypertrophic Cardiomyopathy',
    classification: ['disease'],
    path: 'hypertrophic-cardiomyopathy',
    isDisabled: true,
  },
  {
    id: '7b615f41-a9ea-4a97-bb3c-6922093686b8',
    title: 'Hypoglycemia',
    classification: ['disease'],
    path: 'hypoglycemia',
    isDisabled: true,
  },
  {
    id: 'e8d24858-0aa0-40e7-8d8a-10660fe59dc1',
    title: 'Opioid Overdose',
    classification: ['disease'],
    path: 'opioid-overdose',
    isDisabled: true,
  },
  {
    id: '20a81090-3674-4ab1-b3ed-d139874e34b6',
    title: 'Prosthetic Valve Dysfunction',
    classification: ['disease'],
    path: 'prosthetic-valve-dysfunction',
    isDisabled: true,
  },
  {
    id: '5322704c-253d-4f18-9684-8bd1d9da1546',
    title: 'Pulmonary Embolism',
    classification: ['disease'],
    path: 'pulmonary-embolism',
    isDisabled: true,
  },
  {
    id: 'cda81da6-a0fd-4d5a-a879-87df03705023',
    title: 'Rapid Supraventricular Tachycardia',
    classification: ['disease'],
    path: 'rapid-supraventricular-tachycardia',
    isDisabled: true,
  },
  {
    id: '4a26f51d-dfe6-42eb-b095-6a94f9a54094',
    title: 'Seizure',
    classification: ['disease'],
    path: 'seizure',
    isDisabled: true,
  },
  {
    id: '7ac3dca1-fa22-4425-bf79-29dbdaf24e72',
    title: 'Sick Sinus Syndrome',
    classification: ['disease'],
    path: 'sick-sinus-syndrome',
    isDisabled: true,
  },
  {
    id: '3386a2b7-493a-4c6f-a1b0-6f918a29abf3',
    title: 'Structural Obstruction',
    classification: ['disease'],
    path: 'structural-obstruction',
    isDisabled: true,
  },
  {
    id: 'c615ff96-41af-4836-bda2-4d6ca80876c0',
    title: 'Subarachnoid Hemorrhage',
    classification: ['disease'],
    path: 'subarachnoid-hemorrhage',
    isDisabled: true,
  },
  {
    id: 'e042b39a-1aa9-43ea-ac47-3ec5761cf144',
    title: 'Subclavian Steal',
    classification: ['disease'],
    path: 'subclavian-steal',
    isDisabled: true,
  },
  {
    id: '45990b1d-2e12-4db5-ae24-506bd7265e21',
    title: 'Traumatic Brain Injury',
    classification: ['disease'],
    path: 'traumatic-brain-injury',
    isDisabled: true,
  },
  {
    id: 'd58b3c0b-23ce-4f0e-830a-c29a9c3b93fd',
    title: 'Ventricular Tachycardia',
    classification: ['disease'],
    path: 'ventricular-tachycardia',
    isDisabled: true,
  },
];
