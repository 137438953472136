<div class="working-ddx-table border bg-white rounded" ariaLabel="Working Differential Diagnosis Table">
    <div class="heading bg-white rounded">
        <div
            class="title-section d-flex flex-column flex-md-row justify-content-start justify-content-lg-between align-items-start align-items-md-center bg-light rounded">
            <h1 class="text-primary-blue">{{totalDDx}} WORKING DDx TO REVIEW</h1>
            <app-icon-chips class="justify-content-end w-100" [icons]="iconChips"
                ariaLabel="Illness script severity indicators"></app-icon-chips>
        </div>
    </div>
    <div class="table-wrapper position-relative overflow-hidden">
        <div class="table-container overflow-auto" #tableContainer>
            <div class="table-responsive" tabindex="0">
                <table class="table table-bordered bg-white" aria-labelledby="working-ddx-title">
                    <caption class="sr-only">Working Differential Diagnosis Table</caption>
                    <thead class="bg-dark text-white">
                        <tr>
                            <th class="rounded-left" scope="col">Working DDx</th>
                            <th scope="col">Risk factors</th>
                            <th scope="col">Associated symptoms</th>
                            <th scope="col">Signs</th>
                            <th class="rounded-right" scope="col">Workup</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of ddxTableList; let i = index">
                            <th scope="row" class="position-sticky left-0 bg-light">
                                <div class="cta-item">
                                    <button (click)="openIllnessScript(row.illnessId)"
                                        (keydown.enter)="openIllnessScript(row.illnessId)" role="button"
                                        aria-haspopup="true"
                                        [attr.aria-label]="'Open illness script for ' + row.illnessName"
                                        class="cta-title w-100 d-flex align-items-center btn btn-link text-primary-blue">
                                        <span class="flex-grow-1 text-wrap">{{row.illnessName}}</span>
                                        <app-svg class="icon" [svg]="'chevron-right-small'" [fill]="'#083377'"
                                            aria-hidden="true"></app-svg>
                                    </button>
                                    <div class="diagnoses-chips d-flex align-content-center gap-2 w-100">
                                        <app-icon-chips class="chips" *ngIf="row.emergent" [icons]="[emergentIcon]"
                                            [attr.aria-label]="row.illnessName + ' is an Emergent diagnose'"></app-icon-chips>
                                        <app-icon-chips class="chips" *ngIf="row.mustNotMiss"
                                            [icons]="[mustNotMissIcon]"
                                            [attr.aria-label]="row.illnessName + ' is a Must Not Miss diagnose'"></app-icon-chips>
                                    </div>
                                </div>
                            </th>
                            <ng-container *ngIf="shouldMergeRow(row); else separateCells">
                                <td colspan="3" class="merged-cell text-center h-100">
                                    <app-rich-text class="content h-100"
                                        [content]="'No content available'"></app-rich-text>
                                </td>
                            </ng-container>
                            <ng-template #separateCells>
                                <td class="h-100">
                                    <div class="content d-flex align-items-start justify-content-center h-100">
                                        <app-rich-text
                                            [content]="row.riskFactors ? row.riskFactors : 'No content available'"></app-rich-text>
                                    </div>
                                </td>
                                <td class="h-100">
                                    <div class="content d-flex align-items-start justify-content-center h-100">
                                        <app-rich-text
                                            [content]="row.symptoms? row.symptoms : 'No content available'"></app-rich-text>
                                    </div>
                                </td>
                                <td class="h-100">
                                    <div class="content d-flex align-items-start justify-content-center h-100">
                                        <app-rich-text
                                            [content]="row.signs? row.signs : 'No content available'"></app-rich-text>
                                    </div>
                                </td>
                            </ng-template>
                            <td>
                                <div class="content cta-item">
                                    <button (click)="openWorkup(row)" (keydown.enter)="openWorkup(row)" role="button"
                                        aria-haspopup="true" [attr.aria-label]="'Open workup for ' + row.illnessName"
                                        class="cta-title d-flex align-items-center gap-2 btn btn-link text-primary-blue">
                                        <span class="flex-grow-1 text-wrap">WORKUP</span>
                                        <app-svg class="icon" [svg]="'chevron-right-small'" [fill]="'#083377'"
                                            aria-hidden="true"></app-svg>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div>
        <app-step-cta [options]="options$ | async" (clicked)="onRedirect()"
            [type]="StepType.DIFFERENTIAL"></app-step-cta>
    </div>
</div>

<app-modal #modal size="large" class="workup-drawer" [headerText]="modalTitle" aria-labelledby="modal-title"
    (onClose)="visibleIllness=null">
    <app-spinner-loader *ngIf="!visibleIllness && selectedModal !== 'workup'" />
    <div *ngIf="visibleIllness && selectedModal === 'illness'; else table">
        <app-single-illness [illness]="visibleIllness"></app-single-illness>
    </div>
    <ng-template #table>
        <div class="header d-flex justify-content-between sticky-top bg-white shadow">
            <h2 id="modal-title">{{workupData.illnessName}}</h2>
            <div class="labels d-flex align-items-center">
                <app-icon-chips class="chips" *ngIf="workupData.emergent" [icons]="[emergentWitLabelIcon]"
                    [attr.aria-label]="workupData.illnessName + ' is an Emergent diagnose'"></app-icon-chips>
                <app-icon-chips class="chips" *ngIf="workupData.mustNotMiss" [icons]="[mustNotMissWitLabelIcon]"
                    [attr.aria-label]="workupData.illnessName + ' is a Must Not Miss diagnose'"></app-icon-chips>
            </div>
        </div>
        <div class="workup-wrapper">
            <app-rich-text [content]="workupData.info" />
        </div>
    </ng-template>
</app-modal>