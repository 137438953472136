import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Algorithm } from './algorithm';

@Injectable()
export class AlgorithmService {
  constructor(private httpClient: HttpClient) {}

  getById(manifestationId: string): Observable<{ list: Algorithm[] }> {
    return this.httpClient.get<{ list: Algorithm[] }>(
      'assets/data/' + manifestationId + '/en_us/1/algorithms/example.json'
    );
  }
}
