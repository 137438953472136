import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  BaseNode,
  ContentNode,
  HierarchyNode,
  ImageNode,
  NodeTemplate,
  ProblemNode,
} from './data.model';
import { ImageViewModel } from 'src/app/components/images-carousel/images-carousel.types';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private http: HttpClient) {}
  locale = 'en_us';
  version = '1';

  getHierarchy(problemId: string): Observable<HierarchyNode> {
    return this.http
      .get<HierarchyNode>(
        `assets/data/${problemId}/${this.locale}/${this.version}/hierarchy.json`
      )
      .pipe(
        map((response) => {
          return response;
        })
      )
      .pipe(
        catchError((error) => {
          console.error('Error fetching hierarchy:', error);
          return of(null as unknown as HierarchyNode);
        })
      );
  }

  fetchContentNodes(filePath: string): Observable<ContentNode[]> {
    return this.getJsonFile<any>(filePath).pipe(
      map((fileData) => {
        return fileData.content as ContentNode[];
      }),
      catchError((error) => {
        console.error('Error fetching content nodes:', error);
        return of([]);
      })
    );
  }

  findNode(node: HierarchyNode, id: string): HierarchyNode | null {
    const flatList: HierarchyNode[] = [];
    flattenNodes(node);

    function flattenNodes(currentNode: HierarchyNode) {
      if (currentNode.template !== NodeTemplate.ILLNESS_SCRIPT) {
        flatList.push(currentNode);
      }
      if (currentNode.children && currentNode.children.length > 0) {
        for (const child of currentNode.children) {
          flattenNodes(child);
        }
      }
    }
    return flatList.find((listNode) => listNode.id === id) ?? null;
  }

  findStepNode(node: HierarchyNode, stepId: string): HierarchyNode | null {
    if (node.id === stepId) {
      return node;
    }
    for (const child of node.children) {
      const result = this.findStepNode(child, stepId);
      if (result) {
        return result;
      }
    }
    console.error(
      `Step node with id ${stepId} not found in node with id ${node.id}`
    );
    return null;
  }

  mapContentNodesToString = (
    contentNodes: ContentNode[],
    fieldName: string
  ): string => {
    return contentNodes
      .filter((node) => node.fieldName === fieldName)
      .map((node) => decodeURIComponent(node.data as string))
      .join('');
  };

  mapContentNodesToBoolean = (
    contentNodes: ContentNode[],
    fieldName: string,
    content: string
  ): boolean => {
    return contentNodes
      .filter((node) => node.fieldName === fieldName)
      .some(
        (node) =>
          Array.isArray(node.data) &&
          node.data
            .map((item) => this.transformString(item))
            .includes(this.transformString(content))
      );
  };

  mapContentNodesToArray = (
    contentNodes: ContentNode[],
    fieldName: string
  ): any[] => {
    return contentNodes
      .filter((node) => node.fieldName === fieldName)
      .map((node) => node.data as []);
  };

  mapContentNodesToImageViewModel = (
    problemId: string,
    contentNodes: ContentNode[],
    fieldName: string
  ): ImageViewModel[] => {
    return contentNodes
      .filter((node) => node.fieldName === fieldName)
      .flatMap((node) => {
        const data = node.data as unknown as ImageNode[];
        return data.map((item) => {
          return {
            url: item.path,
            altText: item.caption,
          } as ImageViewModel;
        });
      });
  };

  getProblemById(id: string): Observable<ProblemNode> {
    return this.getJsonFile<BaseNode>(
      `${id}/${this.locale}/${this.version}/hierarchy.json`
    )
      .pipe(
        mergeMap((response) => {
          return this.getJsonFile<ProblemNode>(
            `${id}/${this.locale}/${this.version}/home/${response.slug}.json`
          );
        })
      )
      .pipe(
        catchError((error) => {
          console.error('Error fetching bundle:', error);
          return of(null as unknown as ProblemNode);
        })
      );
  }

  getProblemIllnessScriptById(
    problemId: string,
    illnessScriptId: string
  ): Observable<BaseNode[]> {
    return this.getHierarchy(problemId).pipe(
      mergeMap((hierarchy) => {
        const illness = this.findNodesByTemplate(
          hierarchy,
          NodeTemplate.ILLNESS_SCRIPT
        ).filter((step) => step.id === illnessScriptId);

        if (illness.length === 0) {
          console.error(`No illness script found with id ${illnessScriptId}`);
          return of([] as BaseNode[]);
        }

        return this.getJsonFile<BaseNode[]>(
          `${problemId}/${this.locale}/${this.version}/illness_script/${illness[0].slug}.json`
        );
      })
    );
  }

  getIllnessScriptsByProblemId(problemId: string): Observable<BaseNode[]> {
    return this.getHierarchy(problemId).pipe(
      switchMap((hierarchy) => {
        const illnessScripts = this.findNodesByTemplate(
          hierarchy,
          NodeTemplate.ILLNESS_SCRIPT
        );

        if (illnessScripts.length === 0) {
          console.error(`No illness scripts found in problem id ${problemId}`);
          return of([]);
        }

        const slugs = illnessScripts.map((script) => script.slug);
        const folderName = `${problemId}/${this.locale}/${this.version}/illness_script`;
        return this.fetchFilesFromList<BaseNode>(folderName, slugs);
      }),
      catchError((error) => {
        console.error('Error fetching illness scripts:', error);
        return of([]);
      })
    );
  }

  getIllnessScriptsByStepId(
    problemId: string,
    stepId: string
  ): Observable<BaseNode[]> {
    return this.getHierarchy(problemId).pipe(
      switchMap((hierarchy) => {
        const ddxNode = this.findNodeById(hierarchy, stepId);
        if (!ddxNode || ddxNode.template !== NodeTemplate.STEP) {
          console.error(`No step found with id ${stepId}`);
          return of([]);
        }

        const illnessScripts = this.findNodesByTemplate(
          ddxNode,
          NodeTemplate.ILLNESS_SCRIPT
        );

        if (illnessScripts.length === 0) {
          console.error(`No illness scripts found for Step with id ${stepId}`);
          return of([]);
        }

        const slugs = illnessScripts.map((script) => script.slug);
        const folderName = `${problemId}/${this.locale}/${this.version}/illness_script`;
        return this.fetchFilesFromList<BaseNode>(folderName, slugs);
      }),
      catchError((error) => {
        console.error('Error fetching illness scripts:', error);
        return of([]);
      })
    );
  }

  getIllnessScriptsByDDxId(
    problemId: string,
    DDxId: string
  ): Observable<BaseNode[]> {
    return this.getHierarchy(problemId).pipe(
      switchMap((hierarchy) => {
        const ddxNode = this.findNodeById(hierarchy, DDxId);
        if (!ddxNode || ddxNode.template !== NodeTemplate.WORKING_DDX) {
          console.error(`No working DDx found with id ${DDxId}`);
          return of([]);
        }

        const illnessScripts = this.findNodesByTemplate(
          ddxNode,
          NodeTemplate.ILLNESS_SCRIPT
        );

        if (illnessScripts.length === 0) {
          console.error(`No illness scripts found for DDx with id ${DDxId}`);
          return of([]);
        }

        const slugs = illnessScripts.map((script) => script.slug);
        const folderName = `${problemId}/${this.locale}/${this.version}/illness_script`;
        return this.fetchFilesFromList<BaseNode>(folderName, slugs);
      }),
      catchError((error) => {
        console.error('Error fetching illness scripts:', error);
        return of([]);
      })
    );
  }

  getStepsByProblemId(problemId: string): Observable<BaseNode[]> {
    return this.getHierarchy(problemId).pipe(
      mergeMap((hierarchy) => {
        const steps = this.findNodesByTemplate(hierarchy, NodeTemplate.STEP);

        if (steps.length === 0) {
          console.error(`No steps in problem id ${problemId}`);
          return of(null as unknown as BaseNode[]);
        }

        const slugs = steps.map((step) => step.slug);
        const folderName = `${problemId}/${this.locale}/${this.version}/step`;
        return this.fetchFilesFromList<BaseNode>(folderName, slugs);
      })
    );
  }

  getStepById(problemId: string, stepId: string): Observable<BaseNode> {
    return this.getHierarchy(problemId).pipe(
      mergeMap((hierarchy) => {
        const step = this.findNodesByTemplate(
          hierarchy,
          NodeTemplate.STEP
        ).filter((step) => step.id === stepId)[0] as HierarchyNode | undefined;

        if (!step) {
          console.error(`No steps in problem id ${problemId}`);
          return of(null as unknown as BaseNode);
        }

        return this.getJsonFile<BaseNode>(
          `${problemId}/${this.locale}/${this.version}/step/${step.slug}.json`
        );
      })
    );
  }

  getDDxsByProblemId(problemId: string): Observable<BaseNode[]> {
    return this.getHierarchy(problemId).pipe(
      mergeMap((hierarchy) => {
        const ddxs = this.findNodesByTemplate(
          hierarchy,
          NodeTemplate.WORKING_DDX
        );

        if (ddxs.length === 0) {
          console.error(`No DDxs in problem id ${problemId}`);
          return of(null as unknown as BaseNode[]);
        }

        const slugs = ddxs.map((ddx) => ddx.slug);
        const folderName = `${problemId}/${this.locale}/${this.version}/working_ddx`;
        return this.fetchFilesFromList<BaseNode>(folderName, slugs);
      })
    );
  }

  getDDxById(
    problemId: string,
    stepId: string,
    DDxId: string
  ): Observable<BaseNode> {
    return this.getHierarchy(problemId).pipe(
      mergeMap((hierarchy) => {
        const ddx = this.findNodesByTemplate(
          hierarchy,
          NodeTemplate.STEP
        ).filter(
          (step) =>
            step.id === stepId &&
            step.children.find(
              (child) =>
                child.id === DDxId &&
                child.template === NodeTemplate.WORKING_DDX
            )
        )[0] as HierarchyNode | undefined;

        if (!ddx) {
          console.error(
            `No DDxs with id ${DDxId} in step id ${stepId} and problem id ${problemId}`
          );
          return of(null as unknown as BaseNode);
        }

        return this.getJsonFile<BaseNode>(
          `${problemId}/${this.locale}/${this.version}/working_ddx/${ddx.slug}.json`
        );
      })
    );
  }

  findNodeById(node: HierarchyNode, id: string): HierarchyNode | null {
    if (node.id === id) {
      return node;
    }

    for (const child of node.children) {
      const found = this.findNodeById(child, id);
      if (found) {
        return found;
      }
    }

    return null;
  }

  findNodesByTemplate(
    node: HierarchyNode,
    template: NodeTemplate
  ): HierarchyNode[] {
    const result: HierarchyNode[] = [];

    if (node.template === template) {
      result.push(node);
    }

    for (const child of node.children) {
      result.push(...this.findNodesByTemplate(child, template));
    }

    return result;
  }

  findChildNodesByTemplate(node: HierarchyNode, template: string) {
    const result: HierarchyNode[] = [];
    for (const child of node.children) {
      if (child.template === template) {
        result.push(child);
      }
    }
    return result;
  }

  // TODO: If IllnessScript integration is done by API resquet, this method should be removed
  // Otherwise, it should be renamed and refactored to map the IllnessScript bundle
  tempIllnessScriptSource(slug: string): Observable<BaseNode> {
    return this.getJsonFile<BaseNode>(`illness_script/${slug}.json`);
  }

  private fetchFilesFromList<T>(
    folderName: string,
    slugs: string[]
  ): Observable<T[]> {
    return forkJoin(
      slugs.map((slug) =>
        this.getJsonFile<T>(`${folderName}/${slug}.json`).pipe(
          catchError((error) => {
            console.error(
              `Error fetching file for slug ${slug} from ${folderName}`,
              error
            );
            return of(null as T);
          })
        )
      )
    ).pipe(map((results) => results.filter((result) => result !== null)));
  }

  private getJsonFile<T>(path: string): Observable<T> {
    return this.http.get<T>(`assets/data/${path}`).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        console.error('Error fetching JSON file:', error);
        return of(null as unknown as T);
      })
    );
  }

  private transformString(content: string): string {
    return content.toLowerCase().replace(/ /g, '_');
  }
}
