import { Injectable } from '@angular/core';
import { Manifestation, ProblemNodeTemplate } from './manifestation';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { TaxonomyModel } from '../mock-integration/taxonomy/taxonomy.model';
import { TaxonomyService } from '../mock-integration/taxonomy/taxonomy.service';
import { DataService } from '../mock-integration/data/data.service';
import { ProblemNode } from '../mock-integration/data/data.model';
import { Router } from '@angular/router';
import { AuthorizeService, Cookie } from '../api/authorize.service';
import { CDNService } from '../api/cdn.service';

@Injectable()
export class ManifestationService {
  constructor(
    private readonly taxonomyService: TaxonomyService,
    // private readonly dataService: CDNService,
    private readonly dataService: DataService,
    private readonly router: Router
  ) {}

  get(): Observable<Manifestation[]> {
    return this.taxonomyService
      .getTaxonomyByClassification('', 'problem', 0, 20)
      .pipe(map((problems) => this.mapTaxonomyModelToManifestation(problems)));
  }

  getById(id: string): Observable<Manifestation> {
    return this.dataService.getProblemById(id).pipe(
      map((problem: ProblemNode) => {
        return this.mapNodeToProblemModel([problem])[0];
      }),
      switchMap((manifestation: Manifestation) =>
        this.getNextSteps(id).pipe(
          map((nextSteps) => {
            manifestation.nextSteps = nextSteps;
            return manifestation;
          })
        )
      ),
      catchError((error) => {
        console.error(error);
        this.router.navigate(['/not-found']);
        return of();
      })
    );
  }

  getNextSteps(id: string): Observable<string[]> {
    return this.dataService.getStepsByProblemId(id).pipe(
      map((steps) => {
        return steps.map((step) => step.id);
      })
    );
  }

  private mapNodeToProblemModel(problems: ProblemNode[]): Manifestation[] {
    const manifestations = problems.map((problem: ProblemNode) => {
      const manifestation: Manifestation = {
        id: problem.id,
        displayName: problem.title,
        problemType: ['signs', 'symptoms'],
        summary: this.dataService.mapContentNodesToString(
          problem.content,
          ProblemNodeTemplate.SUMMARY
        ),
        images: this.dataService.mapContentNodesToImageViewModel(
          problem.id,
          problem.content,
          ProblemNodeTemplate.IMAGES
        ),
        aliases: this.dataService.mapContentNodesToArray(
          problem.content,
          ProblemNodeTemplate.ALIASES
        ),
        nextSteps: [],
        problemStatement: this.dataService.mapContentNodesToString(
          problem.content,
          ProblemNodeTemplate.PROBLEM_STATEMENT
        ),
      };
      return manifestation;
    });
    return manifestations;
  }

  private mapTaxonomyModelToManifestation(
    problems: TaxonomyModel[]
  ): Manifestation[] {
    const manifestations = problems.map((problem: TaxonomyModel) => {
      const manifestation: Manifestation = {
        id: problem.id,
        displayName: problem.title,
        problemType: problem.classification,
        summary: '',
        images: [],
        aliases: [],
        nextSteps: [],
        problemStatement: '',
        isDisabled: problem.isDisabled,
      };
      return manifestation;
    });
    return manifestations;
  }
}
